import React, {useEffect, useState} from 'react';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import Seo from '../../../../components/seo';
import Container from '../../../../components/layout/container';
import beautyTechForumDao from '../../../../dao/beautyTechForumDao';
import _isEmpty from 'lodash/isEmpty';
import EventCard from '../../beautytechforumvirtual/components/EventCard';
import {Trans, useTranslation} from 'react-i18next';
import '../../beautytechforumvirtual/home/index.css';
import Parser from 'react-html-parser';
import GBTFEvent from '../event';
import BlankLayout from '../../../../components/layout/BlankLayout';
import {useParams} from 'react-router-dom';
import Footer4BusinessAsync from '../../../../components/layout/footer4business/async';
import PrerenderLayout from '../../../../components/layout/PrerenderLayout';
import classnames from 'classnames';

export default function GlobalBeautyTechForum() {
  const {t, i18n} = useTranslation();
  const [eventList, setEventList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [courseData, setCourseData] = useState({});
  const {gbtfLang, courseKey} = useParams();
  const languageType = gbtfLang || 'enu';
  const [speaker, setSpeaker] = useState({});
  const [courseInfo, setCourseInfo] = useState({});
  const [speakerId, setSpeakerId] = useState([]);
  const [sessions, setSessions] = useState({});
  const [course, setCourse] = useState(null);
  const seo = (
    <Seo
      key="seo"
      title={t(
        'seo:business.beauty.tech.forum.virtual.title',
        'The Global Beauty Tech Forum Virtual Event Archive | PERFECT'
      )}
      description={t(
        'seo:business.beauty.tech.forum.virtual.desc',
        'Learn more about our expert-led monthly webinars showcasing the fundamentals of AI & AR beauty technologies and their real-world practical application.'
      )}
      image={_get(eventList, '[0].sessionImageUrl')}
      breadcrumb={[
        {
          position: 1,
          name: 'Home',
          item: '/',
        },
        {
          position: 2,
          name: 'Business',
          item: '/business',
        },
        {
          position: 3,
          name: 'GBTF',
          item: '/business/global-beauty-tech-forum',
        },
      ]}
    />
  );

  useEffect(() => {
    if (!course) return;
    setIsLoaded(true);
    setCourseData({
      courseTitle: course.courseTitle,
      courseDesc: course.courseDesc,
      courseKey: course.courseKey,
      courseName: course.courseName,
      isLatest: course.isLatest,
      attrs: course.attrs,
      languageType: course.languageType,
    });
    setEventList(_sortBy(sessions, ['sessionStartDate']));
    if (!_isEmpty(course) && course.pageType === 'EVENT_PAGE') {
      setCourseInfo(JSON.parse(course.attrs.META_COURSES));
      if (_get(course, 'attrs.SPEAKER_ORDER'))
        setSpeakerId(JSON.parse(course.attrs.SPEAKER_ORDER));
    }
    window.scrollTo(0, 0);
  }, [sessions, course]);

  const getSpeaker = async id => {
    return await beautyTechForumDao.querySpeaker({speakerId: id});
  };
  useEffect(() => {
    (async () => {
      const params = {languageType, courseType: 'VIRTUAL_EVENT'};
      const res = await beautyTechForumDao.listCourse({
        courseType: 'VIRTUAL_EVENT',
      });
      const currentGbtf = res.filter(
        rrr => rrr.isLatest && rrr.languageType === languageType
      )[0];
      const course =
        courseKey && !_isEmpty(res.filter(rrr => rrr.courseKey === courseKey))
          ? res.filter(rrr => rrr.courseKey === courseKey)[0]
          : currentGbtf;
      beautyTechForumDao
        .listSessionsByCourseId({...params, courseId: course.id})
        .then(result => {
          setSessions(result);
        });
      setCourse(course);
    })();
  }, [languageType, courseKey]);

  useEffect(() => {
    if (!_isEmpty(speakerId)) {
      let speakertmp = {};
      speakerId.forEach(s => {
        getSpeaker(s).then(val => {
          speakertmp[val.id] = val;
          setSpeaker(speakertmp);
        });
      });
    }
  }, [speakerId]);

  if (!isLoaded) {
    return <div />;
  }

  if (!_isEmpty(course) && _get(course, 'pageType') === 'EVENT_PAGE') {
    return (
      <div
        className={classnames({
          'pf-language': true,
          'pf-language-ja': i18n.language === 'ja',
        })}
      >
        {seo}
        <GBTFEvent
          session={sessions}
          courseData={courseData}
          courseInfo={courseInfo}
          speaker={speaker}
          speakerId={speakerId}
          languageType={languageType}
        />
        <Footer4BusinessAsync pageReady={true} isGbtfEvent={true} />
      </div>
    );
  }

  return (
    <PrerenderLayout showContactSales={true}>
      <div>
        {seo}
        <Container mobileType={true}>
          <div className="btfv-title">{Parser(courseData.courseTitle)}</div>
          <div className="btfv-desc">{Parser(courseData.courseDesc)}</div>
          <h2 className="btfv-title">{courseData.courseName}</h2>
          <div className="btfv-events">
            {!_isEmpty(eventList) &&
              eventList.map((e, n) => (
                <EventCard
                  key={`panel-${n}`}
                  label={
                    <div>
                      <Trans i18nKey="business:p.b.b.v.home.panel">PANEL</Trans>{' '}
                      {_get(e, 'sessionNumber')}
                    </div>
                  }
                  labelColor={'#73a9c6'}
                  showWatchReplay={true}
                  title={e.sessionTitle}
                  speakers={e.sessionSpeakers}
                  actionLink={`/business/global-beauty-tech-forum/detail/${
                    e.id
                  }`}
                  image={e.sessionImageUrl}
                  actionType={e.videoUrl ? 'video' : 'register'}
                  eventList={eventList}
                />
              ))}
          </div>
        </Container>
      </div>
    </PrerenderLayout>
  );
}
