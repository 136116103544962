import i18n from 'i18next';
import _get from 'lodash/get';
import _join from 'lodash/join';
import _upperFirst from 'lodash/upperFirst';
import _camelCase from 'lodash/camelCase';
import webUtils from './webUtils';
import filterMap from './config/blogFilters';
import _flatten from 'lodash/flatten';
import {Trans} from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';

const utils = {
  isEnabled: () =>
    ['en', 'zh_TW', 'zh_CN', 'ja', 'fr', 'ko', 'de', 'es', 'it'].indexOf(
      webUtils.determineLanguage(i18n.language)
    ) !== -1,
  convertLang: lang => {
    const langMap = {
      zh_CN: 'chs',
      'zh-cn': 'chs',
      'zh-tw': 'cht',
      zh_TW: 'cht',
      en: 'enu',
      fr: 'fra',
      ja: 'jpn',
      ko: 'kor',
      de: 'deu',
      es: 'esp',
      it: 'ita',
      pt: 'ptb',
    };
    return langMap[lang] || 'enu';
  },
  convertLanguage: lang => {
    const langMap = {
      chs: 'zh-cn',
      cht: 'zh-tw',
      enu: 'en',
      fra: 'fr',
      jpn: 'ja',
      kor: 'ko',
      deu: 'de',
      esp: 'es',
      ita: 'it',
      ptb: 'pt',
    };
    return langMap[lang];
  },
  getAuthorLink: author => {
    const type = webUtils.isBusiness() ? 'business' : 'consumer';
    return _get(author, 'authorHandle')
      ? `/${type}/blog/author/${author.authorHandle}`
      : `/${type}/blog/author?authorId=${_get(author, 'id')}`;
  },
  getPostLink: post => {
    const type = webUtils.isBusiness() ? 'business' : 'consumer';
    return _get(post, 'postHandle')
      ? `/${type}/blog/${post.postHandle}`
      : `/${type}/blog/post?postId=${_get(post, 'id')}`;
  },
  getNewsLink: post => {
    const type = webUtils.isBusiness() ? 'business' : 'consumer';
    const language = webUtils.getLangPath(language);
    return `${language}/${type}/news/detail/${_get(post, 'id')}`;
  },
  joinKeywordsToHashtags: (postKeywords = [], separator = ',') => {
    const hashtags = postKeywords.map(item =>
      utils.toHashtagFormat(_get(item, 'keyword.title'))
    );
    return _join(hashtags, separator);
  },
  getTransformedMonth: (language, dayObj) => {
    const monthMap = {
      zh_cn: dayObj.format('M'),
      zh_tw: dayObj.format('M'),
      'zh-cn': dayObj.format('M'),
      'zh-tw': dayObj.format('M'),
      en: dayObj.format('MMM'),
      ja: dayObj.format('M'),
      fr: webUtils.convertToFrenchMonth(dayObj.month()),
      de: dayObj.format('MMM'),
      es: dayObj.format('MMM'),
      ko: dayObj.format('MMM'),
      it: dayObj.format('MMM'),
      pt: dayObj.format('MMM'),
    };
    return monthMap[language];
  },
  toHashtagFormat(string = '') {
    return _upperFirst(_camelCase(string));
  },
  getInitialFilterValue: () => ({
    FOCUS: webUtils.isBusiness() ? 'BF_BUSINESS_FOCUS' : 'CF_CONSUMER_FOCUS',
    BUSINESS_SERVICES: [],
    BUSINESS_TOPICS: [],
    CONSUMER_APPS: [],
    CONSUMER_TOPICS: [],
  }),
  flattenedFilter: _flatten(Object.values(filterMap)).reduce((o, f) => {
    o = {...o, ...f};
    return o;
  }, {}),
  getCategory: category => {
    const configs = [
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.category.success.story">
            Success Story
          </Trans>
        ),
        url: 'success-story',
        value: 'SUCCESS_STORY',
      },
      {
        label: <Trans i18nKey="business:c.l.s.popupmenu.makeup">Makeup</Trans>,
        url: 'makeup',
        value: 'MAKEUP',
        desc: (
          <Trans i18nKey="business:c.l.s.popupmenu.makeup.desc">
            Discover the latest face filter trends, exciting AI features like AI
            aging and clothes changing, as well as powerful photo editing tools.
            Transform your selfies with background editing and stunning photo
            effects, and have fun with the newest AI filters.
          </Trans>
        ),
      },
      {
        label: (
          <Trans i18nKey="business:u.t.b.f.bt.skincare">AI Skincare</Trans>
        ),
        url: 'ai-skincare',
        value: 'AI_SKINCARE',
        desc: (
          <Trans i18nKey="business:u.t.b.f.bt.skincare.desc">
            We share everything you need to know about skincare, from analyzing
            your skin type to the best routines and tips for achieving youthful,
            glowing skin. Discover the top AI skin analysis tools and learn how
            they can help you understand and improve your skin’s condition.
          </Trans>
        ),
      },
      {
        label: <Trans i18nKey="business:u.t.b.f.bt.hair">AI/AR Hair</Trans>,
        url: 'hair',
        value: 'HAIR',
        desc: (
          <Trans i18nKey="business:u.t.b.f.bt.hair.desc">
            Discover the latest hairstyle and hair color trends, and learn how
            to test them virtually on your photos with the best AI hairstyle
            try-on app. Use AI to see how you would look with a new hairstyle or
            hair color, and get the most realistic preview to find the style
            that suits you best.
          </Trans>
        ),
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.category.general">General</Trans>
        ),
        url: 'general',
        value: 'GENERAL',
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.category.beauty.tech">
            On Demand Beauty Tech
          </Trans>
        ),
        url: 'on-demand-beauty-tech',
        value: 'ON_DEMAND_BEAUTY_TECH',
      },
      {
        label: <Trans i18nKey="business:u.t.b.f.bt.retail">Retail</Trans>,
        url: 'retail',
        value: 'RETAIL',
      },
      {
        label: (
          <Trans i18nKey="business:u.t.b.f.bt.ecommerce">E-Commerce</Trans>
        ),
        url: 'commerce',
        value: 'COMMERCE',
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.ct.photo.edit">Photo Editing</Trans>
        ),
        url: 'photo-editing',
        value: 'PHOTO_EDITING',
        desc: (
          <Trans i18nKey="consumer:u.t.b.f.ct.photo.edit.desc">
            Discover a variety of photo editing tips and tricks, including
            object removal, adding people to photos, replacing objects, how to
            do collages, and more. We’ll also share the latest TikTok and
            Instagram trends. Stay tuned for exciting photo editing topics!
          </Trans>
        ),
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.ct.video.edit">Video Editing</Trans>
        ),
        url: 'video-editing',
        value: 'VIDEO_EDITING',
        desc: (
          <Trans i18nKey="consumer:u.t.b.f.ct.video.edit.desc">
            Learn how to edit videos on iPhone and Android with our expert video
            editing tips and tutorials. Perfect for beginners and pros alike,
            our guides will help you master video editing techniques, enhance
            your videos, add AI effects, and create stunning video content
            effortlessly.
          </Trans>
        ),
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.category.selfie.editing">
            Self Editing
          </Trans>
        ),
        url: 'selfie-editing',
        value: 'SELFIE_EDITING',
        desc: (
          <Trans i18nKey="consumer:u.t.b.f.category.selfie.editing.desc">
            Discover the latest face filter trends, exciting AI features like AI
            aging and clothes changing, as well as powerful photo editing tools.
            Transform your selfies with background editing, and stunning photo
            effects, and have fun with the newest AI filters.
          </Trans>
        ),
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.ct.naildesign">Nail Design</Trans>
        ),
        url: 'nail',
        value: 'NAIL',
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.category.online.photo.editing">
            Online Photo Editing
          </Trans>
        ),
        url: 'online-photo-editing',
        value: 'ONLINE_PHOTO_EDITING',
        desc: (
          <Trans i18nKey="consumer:u.t.b.f.category.online.photo.editing.desc">
            Welcome to our blog, where we delve into the latest AI-powered photo
            editing and enhancement techniques. Here, you’ll find
            recommendations for the best tools, practical use cases, and
            insights into AI image generation, photo restoration, color
            correction, and photo background editing.
          </Trans>
        ),
      },
      {
        label: (
          <Trans i18nKey="consumer:u.t.b.f.category.generativeai">
            Generative-AI
          </Trans>
        ),
        url: 'generative-AI',
        value: 'GENERATIVE_AI',
        desc: (
          <Trans i18nKey="consumer:p.b.b.c.generativeai.desc">
            We share everything you need to know about the best AI image
            generator app, help you understand viral AI trends, and turn your
            imagination into reality. Learn tips, tricks, and inspirations for
            text-2-image AI generation to attract more social media viewers and
            grow your channel with YouCam AI Pro's blog.
          </Trans>
        ),
      },
    ];
    const tmp = configs.filter(s => s.url === category || s.value === category);
    if (_isEmpty(tmp)) return {label: '', value: '', url: ''};
    return tmp[0];
  },
  getFeatureTopic: val => {
    return val.filter(
      bt =>
        !bt.startsWith('BF_') &&
        !bt.startsWith('CF_') &&
        !bt.startsWith('BS_') &&
        !bt.startsWith('CA_') &&
        !bt.startsWith('bf-') &&
        !bt.startsWith('cf-') &&
        !bt.startsWith('bs-') &&
        !bt.startsWith('ca-')
    );
  },
  valueConvertToLink: val => {
    return val
      .toLowerCase()
      .replace(/_/g, '-')
      .replace(/ct-/g, '')
      .replace(/bt-/g, '');
  },
  importKlaviyo: () => {
    if (_get(window, 'navigator.userAgent') === 'ReactSnap') return;
    const s = document.createElement('script');
    s.setAttribute('type', 'text/javascript');
    s.setAttribute(
      'src',
      `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=WTaNvt`
    );
    document.head.append(s);
  },
  klaviyoSubscribeList: async ({
    email,
    country,
    language,
    firstName,
    lastName,
  }) => {
    const options = {
      method: 'POST',
      headers: {
        revision: '2023-06-15',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            list_id: 'QQXh6T',
            email,
            properties: {
              Country: country,
              'Website Language': language,
              'First Name': firstName,
              'Last Name': lastName,
              'Email Address': email,
            },
          },
        },
      }),
    };
    await fetch(
      `https://a.klaviyo.com/client/subscriptions/?company_id=WTaNvt`,
      options
    )
      .then(response => {
        console.log({response});
      })
      .catch(err => {
        console.log({err});
      });
  },
};

export default utils;
