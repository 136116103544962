import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation, useRouteMatch} from 'react-router';
import config from './config';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import _first from 'lodash/first';
import _map from 'lodash/map';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import webUtils from '../../utils/webUtils';
import seoDao from '../../dao/seoDao';
import blogUtils from '../../utils/blogUtils';
import {useTranslation} from 'react-i18next';
import utils from '../../utils/utils';
import _orderBy from 'lodash/orderBy';

dayjs.extend(duration);

export default function Seo({
  title = config.defaultTitle,
  description = config.defaultDescription,
  datePublished = null,
  dateModified = null,
  author = null,
  translator = null,
  image = require('../../assets/images/logo_pf.png'),
  url = '',
  type = config.defaultType,
  seoType = config.defaultSeoType,
  disabledLanguages = [],
  metadata = {},
  breadcrumb = [],
  post = {},
  video = {},
  lcpImage,
  keyword = [],
  alternates,
  canonical,
  noindex,
  alternate = true,
  noCanonical = false,
  prev,
  next,
  softwareApplication = '',
  setFaqList = () => {},
}) {
  const {i18n} = useTranslation();
  const lang = webUtils.getLangPath(i18n.language);
  const [seoMeta, setSeoMeta] = useState({});
  const location = useLocation();
  const domain = window.location.hostname;
  const removehreflang = !_isEmpty(alternates) && alternates.length === 1;
  const pageUrlForCn = () => {
    const domain = webUtils.isCnDomain()
      ? 'https://www.perfectcorp.cn'
      : webUtils.getEnvReactAppUrl();
    const getAlternativeLang = () => {
      const priorityLang = [
        'en',
        'ja',
        'de',
        'fr',
        'it',
        'es',
        'pt',
        'ko',
        'zh-tw',
        'zh-cn',
      ];
      const tmp = alternates.map(s => {
        return s.lang;
      });
      const findIndexes = (param1, param2) => {
        let arr1 = [...param1];
        let arr2 = [...param2];
        if (arr1.length < arr2.length) {
          [arr1, arr2] = [arr2, arr1];
        }
        return arr2.map(a2 => arr1.findIndex(a1 => a1 === a2));
      };
      const index =
        findIndexes(tmp, priorityLang)
          .sort()
          .slice(0, 1) || 0;
      return priorityLang[index] === 'en' || !priorityLang[index]
        ? ''
        : `/${priorityLang[index]}`;
    };
    const LangParam = !_isEmpty(alternates)
      ? getAlternativeLang() || ''
      : lang || '';
    const removeLangUrl = webUtils.removePathnameLang(url);
    return url
      ? removeLangUrl.startsWith('/business') ||
        removeLangUrl.startsWith('/consumer')
        ? `${domain}${LangParam}${removeLangUrl}`
        : url
      : `${domain}${location.pathname}`;
  };
  const makeuparNoShow =
    window.location.hostname.indexOf('makeupar') !== -1 &&
    (window.location.href.indexOf('/consumer') !== -1 ||
      i18n.language !== 'en');
  const getCanonicalUrl = () => {
    const hostname =
      webUtils.isChs(i18n.language) && webUtils.isCnDomain()
        ? 'https://www.perfectcorp.cn'
        : webUtils.getEnvReactAppUrl().replace('makeupar', 'perfectcorp');

    return url
      ? url.startsWith('/business') || url.startsWith('/consumer')
        ? `${hostname}${lang}${url}`
        : lang && url.startsWith(lang)
        ? `${hostname}${url}`
        : url
      : `${hostname}${location.pathname}`;
  };
  const pageUrl = url
    ? url.startsWith('/business') || url.startsWith('/consumer')
      ? `${webUtils.getEnvReactAppUrl()}${lang}${url}`
      : lang && url.startsWith(lang)
      ? `${domain}${url}`
      : url
    : `${webUtils.getEnvReactAppUrl()}${location.pathname}`;
  const alternateLangs = webUtils
    .getSupportedLanguages()
    .filter(l => disabledLanguages.indexOf(l) === -1);
  const d1 = datePublished ? dayjs.unix(datePublished / 1000) : null;
  const d2 = dateModified ? dayjs.unix(dateModified / 1000) : null;
  const match = useRouteMatch();

  const metaStr = JSON.stringify(metadata);

  function getAlternateUrl(url, isCn) {
    const domain = isCn
      ? 'https://www.perfectcorp.cn'
      : webUtils.getEnvReactAppUrl();

    return `${domain}${lang}${webUtils
      .removePathnameLang(url)
      .replace(webUtils.getEnvReactAppUrl(), '')}`;
  }

  const getTitle = () => {
    const t = _get(seoMeta, 'title', '');
    return _isEmpty(t) ? title : t;
  };
  const getOgTitle = () => {
    const t = _get(seoMeta, 'ogTitle', '');
    return _isEmpty(t) ? title : t;
  };
  const getDescription = () => {
    const d = _get(seoMeta, 'description', '');
    return _isEmpty(d) ? description : d;
  };

  const getOgDescription = () => {
    const d = _get(seoMeta, 'ogDescription', '');
    return _isEmpty(d) ? description : d;
  };

  const getImage = () => {
    const d = _get(seoMeta, 'imageUrl', '');
    return _isEmpty(d) ? image : d;
  };

  const getSocialMedia = author => {
    const link = [
      author.linkedinUrl || '',
      author.facebookUrl || '',
      author.instagramUrl || '',
      author.youtubeUrl || '',
      author.twitterUrl || '',
    ];
    return link.filter(l => l !== '');
  };

  const getBreadcrumb = () => {
    return breadcrumb.map((b, i) => {
      return {
        ...b,
        item: `${
          b.item
            ? `${webUtils.getEnvReactAppUrl()}${lang}${b.item}`
            : i === breadcrumb.length - 1
            ? `${webUtils.getEnvReactAppUrl()}${match.url}`
            : `${webUtils.getEnvReactAppUrl()}${_first(
                match.url.split(b.name.toLowerCase())
              )}${b.name}`
        }`,
        name: `${b.name === 'post_title' ? post.title : b.name}`,
        '@type': 'ListItem',
      };
    });
  };

  const getBlogPost = () => {
    const doc = new DOMParser().parseFromString(post.postContent, 'text/html');
    return {
      '@type': 'BlogPosting',
      headline: post.title,
      description: post.metaDesc,
      author: {
        '@type': 'Person',
        name: author ? author.displayName : '',
        jobTitle: author ? author.position : '',
        email: author ? author.emailUrl : '',
        description: author ? author.shortDesc : '',
        sameAs: author ? getSocialMedia(author) : [],
        image: author ? author.imageUrl : '',
      },
      translator: {
        '@type': 'Person',
        name: translator ? translator.displayName : '',
      },
      datePublished: d1 ? d1.format() : '',
      dateModified: d2 ? d2.format() : '',
      Publisher: {
        '@type': 'Organization',
        name: 'Perfect Corp',
        logo: {
          '@type': 'ImageObject',
          url:
            'https://bcm-media.beautycircle.com/pfweb/assets/images/logo_pf-wyzzm8nmo4-2732.png',
        },
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': pageUrl,
      },
      image: [_map(doc.images, image => image.src)],
    };
  };

  const getBlogVideoCover = () => {
    const tmp = dayjs
      .unix(post.createdTime / 1000)
      .format()
      .toString();
    return {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: post.title,
      description: post.metaDesc,
      thumbnailUrl: [post.smallImageUrl, post.squareImageUrl],
      uploadDate: tmp.slice(0, tmp.indexOf('T')),
      duration: 'PT0M4S',
      contentUrl: post.videoUrl,
    };
  };

  const getVideoObject = () => {
    return {
      '@type': 'VideoObject',
      name: getTitle(),
      description: getDescription(),
      thumbnailUrl: [video.videoImageUrl],
      contentUrl: pageUrl,
      uploadDate: dayjs.unix(video.createdTime / 1000).format(),
      duration: video.videoDuration
        ? dayjs.duration(video.videoDuration, 'seconds').toISOString()
        : 'PT4M',
    };
  };

  const getHowTo = () => {
    const title = getTitle() || '';
    const totalTime = seoMeta.howToTotalTime || 1;
    const result = {
      '@type': 'HowTo',
      image: {
        '@type': 'ImageObject',
        url: getImage(),
      },
      name: title.replace(' | PERFECT', ''),
      description: getDescription(),
      supply: [
        {
          '@type': 'HowToSupply',
          name: seoMeta.howToSupply,
        },
      ],
      tool: [
        {
          '@type': 'HowToTool',
          name: seoMeta.howToTool,
        },
      ],
      totalTime: dayjs.duration(totalTime, 'minutes').toISOString(),
    };
    if (seoMeta.enableVideo) {
      result.video = {
        '@type': 'VideoObject',
        name: getTitle(),
        description: getDescription(),
        thumbnailUrl: seoMeta.videoCover,
        contentUrl: seoMeta.videoLink,
        embedUrl: '',
        uploadDate: d1 ? d1.format() : '',

        duration: seoMeta.videoDuration
          ? dayjs.duration(seoMeta.videoDuration, 'minutes').toISOString()
          : 'PT4M',
      };
    }
    if (seoMeta.enableHowTo) {
      result.step =
        seoMeta.serpSteps &&
        seoMeta.serpSteps.map(s => ({
          '@type': 'HowToStep',
          name: s.name,
          text: s.text,
          image: s.image,
          url: pageUrl,
        }));
    }

    return result;
  };

  const getfaqData = () => {
    const result = {
      '@type': 'FAQPage',
    };
    result.mainEntity = seoMeta.serpFaqs.map(s => {
      return {
        '@type': 'Question',
        name: s.question,
        acceptedAnswer: {'@type': 'Answer', text: s.answer},
      };
    });
    return result;
  };

  const getAlternate = () => {
    if (makeuparNoShow || !alternate || removehreflang) return;
    if (!_isEmpty(alternates)) {
      return alternates.map(alternate => (
        <link
          key={alternate.lang}
          rel="alternate"
          hrefLang={
            window.location.hostname.indexOf('makeupar') !== -1
              ? 'en-in'
              : alternate.lang
          }
          href={webUtils.getEnvReactAppUrl() + alternate.path}
        />
      ));
    }
    if (window.location.hostname.indexOf('makeupar') !== -1)
      return (
        <link
          key={lang}
          rel="alternate"
          hrefLang={'en-in'}
          href={getAlternateUrl(pageUrl, false)}
        />
      );
    return alternateLangs.map(lang => (
      <link
        key={lang}
        rel="alternate"
        hrefLang={lang}
        href={getAlternateUrl(pageUrl, webUtils.isChs(i18n.language))}
      />
    ));
  };

  useEffect(() => {
    getHowTo();
    getAlternate();
  }, [seoMeta]);

  const structuredDataOrganization = `{
    "@context": "http://schema.org",
    "@graph": [
      ${
        !_isEmpty(breadcrumb)
          ? `{
          "@type": "BreadcrumbList",
          "itemListElement": ${JSON.stringify(getBreadcrumb())}
        },`
          : ``
      } 
      ${!_isEmpty(post) ? `${JSON.stringify(getBlogPost())},` : ``}
      ${!_isEmpty(video) ? `${JSON.stringify(getVideoObject())},` : ``}
      ${seoMeta.enableHowTo ? `${JSON.stringify(getHowTo())},` : ``}
      ${
        !_isEmpty(seoMeta.serpFaqs) && seoMeta.metaType !== 'PAGE'
          ? `${JSON.stringify(getfaqData())},`
          : ``
      }
      {
        "@type": "Corporation",
        "url": "https://${domain}/business",
        "legalName":"Perfect Corp.",
        "potentialAction": {
          "@type":"SearchAction",
          "target": {
            "@type":"EntryPoint",
            "urlTemplate":"https://${domain}/business/gcse?q={search_term_string}&rs=sitelinks_searchbox"
          },
          "query-input":"required name=search_term_string"
        }, 
        "description": "Complete line of Beauty Tech solution to help beauty brands to digitally transform the online shopping experience with AR and AI ecommerce tech.",
        "foundingDate":"2015",
        "sameAs": [
          "https://www.facebook.com/YouCamApps.tw",
          "https://www.instagram.com/youcamapps.tw/?hl=zh-tw",
          "https://www.youtube.com/channel/UC7GeoFYO277cNdRa_k8TWug",
          "https://www.linkedin.com/company/perfect-corp/",
          "https://apps.apple.com/us/developer/perfect-mobile-corp/id1018779369",
          "https://play.google.com/store/apps/dev?id=7878009122471162106" 
        ],
        "founders": [{
          "@type":"Person",
          "name":"Alice Chang"
        }],
        "contactPoint": [{
          "@type": "ContactPoint",
          "email": "appsupport@perfectcorp.com",
          "telephone": "+886-2-8667-1265",
          "contactType": "customer service",
          "url":"https://${domain}/business/contact-us/sales"
        }],
        "address": {
          "@type": "PostalAddress",
          "streetAddress":"14F., No. 98, Minquan Rd.",
          "addressLocality": "Xindian Dist.",
          "addressRegion": "New Taipei City",
          "addressCountry": "Taiwan",
          "postalCode": "23141"
        }
      }
    ]
  }`;

  useEffect(() => {
    if (!_isEmpty(seoMeta)) return;
    (async () => {
      const r =
        !_isEmpty(metadata) && !_isUndefined(metadata.sourceId)
          ? await seoDao.querySEO(metadata)
          : await seoDao.querySEOByPath({
              path: window.location.pathname,
              languageType: blogUtils.convertLang(i18n.language || 'en'),
            });
      if (!r || _isEmpty(r)) return;
      if (_get(r, 'serpFaqs') && !_isEmpty(r.serpFaqs)) {
        r.serpFaqs = _orderBy(r.serpFaqs, ['order'], ['asc']).filter(
          s => s.question && s.answer
        );
      }
      setSeoMeta(r);
      setFaqList(_get(r, 'serpFaqs'));
    })();
  }, [metaStr, window.location.href]);

  const isCn = webUtils.isChs(i18n.language);

  function noindexInMakeupAr() {
    if (!webUtils.isMakeupArUrl()) return false;
    return !(i18n.language === 'en' && webUtils.isBusiness());
  }

  return (
    <div>
      <Helmet>
        <meta name="author" content={config.defaultAuthor} />
        <meta
          name="title"
          content={_isEmpty(getTitle()) ? config.defaultTitle : getTitle()}
        />
        <meta name="image" content={getImage()} />
        <meta
          name="description"
          content={getDescription() || config.defaultDescription}
        />
        <meta
          property="og:description"
          content={getOgDescription() || config.defaultDescription}
        />
        <meta
          property="og:title"
          content={_isEmpty(getOgTitle()) ? config.defaultTitle : getOgTitle()}
        />
        <meta property="og:image" content={getImage()} />
        <meta property="og:url" content={pageUrlForCn()} />
        <meta property="og:type" content={seoType} />
        {isCn && (
          <meta name="keywords" content={keyword} data-react-helmet="true" />
        )}
        {lcpImage && <link rel="preload" as="image" href={lcpImage} />}
        {lcpImage && <link rel="preconnect" href={new URL(lcpImage).host} />}
        {!makeuparNoShow && alternate && !removehreflang && (
          <link rel="alternate" hrefLang="x-default" href={pageUrlForCn()} />
        )}
        {getAlternate()}
        {!noCanonical && (
          <link rel="canonical" href={canonical || getCanonicalUrl()} />
        )}
        <script type="application/ld+json">{structuredDataOrganization}</script>
        {seoMeta.isSoftwareSchema && (
          <script type="application/ld+json">{softwareApplication}</script>
        )}
        <title>{_isEmpty(getTitle()) ? config.defaultTitle : getTitle()}</title>
        {alternate &&
          i18n.language === 'en' &&
          window.location.href.indexOf('/consumer') === -1 &&
          !removehreflang &&
          (window.location.hostname.indexOf('makeupar') !== -1 ? (
            <link
              rel="alternate"
              hrefLang="en"
              href={`https://www.perfectcorp.com${
                lang === 'en' || !lang ? '' : `/${lang}`
              }${webUtils
                .removePathnameLang(pageUrl)
                .replace(webUtils.getEnvReactAppUrl(), '')}`}
              data-react-helmet="true"
            />
          ) : (
            !makeuparNoShow &&
            alternate && (
              <link
                rel="alternate"
                hrefLang="en-in"
                href={`https://www.makeupar.com${
                  lang === 'en' || !lang ? '' : `/${lang}`
                }${webUtils
                  .removePathnameLang(pageUrl)
                  .replace(webUtils.getEnvReactAppUrl(), '')}`}
                data-react-helmet="true"
              />
            )
          ))}
        {(!utils.isProduction || noindex || noindexInMakeupAr()) && (
          <meta name="robots" content="noindex" data-react-helmet="true" />
        )}
        {prev && <link rel="prev" href={prev} />}
        {next && <link rel="next" href={next} />}
      </Helmet>
    </div>
  );
}
