import _get from 'lodash/get';

const StrapiUtils = {
  SectionCombine: content => {
    if (
      !_get(content, 'topBanner') ||
      !_get(content, 'topBanner.backgroundDesktop')
    )
      return content['sections'];
    let tmp = [];
    content['topBanner']['__component'] = 'topBanner';
    tmp.push(content['topBanner']);
    tmp = tmp.concat(content['sections']);
    return tmp;
  },
  getAnchor: value => {
    return value
      .toLowerCase()
      .replaceAll(' ', '_')
      .replace('&', '')
      .replace("'", '');
  },
  stripHTMLTags: str => {
    return str.replace(/<\/?[^>]+(>|$)/g, '').replace('&nbsp;', '');
  },
};

export default StrapiUtils;
