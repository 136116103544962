import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import useWindowScroll from '../hooks/useWindowScroll';
import '../floatingbtns/godemobtn/index.css';
import webUtils from '../../utils/webUtils';
import {useTranslation} from 'react-i18next';

export default function OnlineServiceButton() {
  const {i18n} = useTranslation();
  const scrollTop = useWindowScroll();
  const [isScroll, setIsScroll] = useState(false);
  const functionType = webUtils.getOnlineServiceLink().functionType;
  useEffect(() => {
    if (scrollTop > 0 && !isScroll) {
      setIsScroll(true);
    }
  }, [scrollTop]);

  if (functionType === 'SKINCARE_PRO') return <div />;
  return (
    <div
      className={classnames({
        'pf-language': true,
        'go-demo-btn--container': true,
        'go-demo-btn--container--active': isScroll,
        'white-camera': true,
      })}
      onClick={() => {
        window.open(
          webUtils.goToOnlineService(
            i18n.language,
            webUtils.getOnlineServiceLink().functionType
          )
        );
      }}
    >
      <div
        className="go-demo-text"
        style={{color: '#212529', backgroundColor: '#fff'}}
      >
        <div>TRY-ON</div>
      </div>
    </div>
  );
}
