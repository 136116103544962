import category from './category/routes';
import bearddye from './bearddye/routes';
import nailcolor from './nailcolor/routes';
import beardstyle from './beardstyle/routes';
import shadefinder from './shadefinder/routes';
import skincare from './skincare/routes';
import faceanalyzer from './faceanalyzer/routes';
import facereshape from './facereshape/routes';
import tutorial from './tutorial/routes';
import watches from './watches/routes';
import bracelets from './bracelets/routes';
import rings from './rings/routes';
import personality from './personality/route';
import hairstyle from './hairstyle/route';
import necklace from './necklace/routes';
import threeDViewer from './3dviewer/routes';
import earrings from './earrings/routes';
import stacking from './stacking/routes';
import hairtype from './hairtype/route';
import hairlength from './hairlength/route';

export default [
  category,
  bearddye,
  nailcolor,
  beardstyle,
  shadefinder,
  skincare,
  faceanalyzer,
  facereshape,
  tutorial,
  watches,
  bracelets,
  rings,
  personality,
  hairstyle,
  necklace,
  threeDViewer,
  earrings,
  stacking,
  hairtype,
  hairlength,
];
